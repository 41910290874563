body {
  font-family: 'Inter', sans-serif;
  background-color: #FFFDD0;
  margin: 0;
  padding: 0;
  color: #333;
}

.ranking-container {
  max-width: 1100px;
  margin: 60px auto;
  padding: 50px;
  background: linear-gradient(135deg, #ffffff, #f9fbff);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  text-align: center; 
  transition: all 0.3s ease;
}

.ranking-container h2 {
  font-size: 40px;
  color: #222831;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: none;
  letter-spacing: 1px;
}

.ranking-container h2 span {
  color: #4e73df;
}


.input-container {
  max-width: 750px;
  margin: 0 auto 50px;
  padding: 40px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  border: 2px solid #f1f4fc;
}

.ranking-form {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.form-group {
  flex: 1 1 calc(50% - 15px);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-input {
  padding: 14px;
  border: 2px solid #c5cbe3;
  border-radius: 12px;
  font-size: 15px;
  color: #495057;
  background-color: white;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.form-input:focus {
  border-color: #4e73df;
  outline: none;
  background-color: #ffffff;
}

.search-button {
  padding: 14px 20px;
  background-color: #4e73df;
  color: #ffffff;
  font-size: 17px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  align-self: flex-end;
  box-shadow: 0 5px 15px rgba(78, 115, 223, 0.2);
}

.search-button:hover {
  background-color: #375bb5;
  transform: translateY(-2px);
}

.search-button:disabled {
  background-color: #d6d6d6;
  cursor: not-allowed;
}


.error-message {
  color: #d93025;
  font-size: 14px;
  margin-top: 8px;
  font-weight: 500;
}


.results-container {
  margin-top: 40px;
  background: #ffffff;
  padding: 35px;
  border-radius: 15px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.08);
  margin: 0 auto; 
  max-width: 100%; 
  overflow-x: auto; 
}

.results-container h3 {
  font-size: 32px;
  color: #212529;
  font-weight: 600;
  margin-bottom: 25px;
}

.results-container table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 20px;
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.results-container th,
.results-container td {
  padding: 18px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
  font-size: 15px;
}

.results-container th {
  background-color: #4e73df;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
}

.results-container td {
  background-color: #f9f9fb;
}

.results-container td a {
  color: #4e73df;
  text-decoration: none;
  font-weight: 500;
}

.results-container td a:hover {
  text-decoration: underline;
}

.results-container td:hover {
  background-color: #e3e9ff;
  transition: background-color 0.2s ease;
}


@media (max-width: 768px) {
  .ranking-container {
    padding: 40px;
  }

  .input-container {
    padding: 30px;
    margin-bottom: 30px;
  }

  .form-group {
    flex: 1 1 100%;
  }

  .search-button {
    align-self: stretch;
    font-size: 16px;
  }

  .results-container {
    padding: 25px;
  }

  .results-container table {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .ranking-container {
    padding: 30px;
  }

  .search-button {
    font-size: 15px;
    padding: 12px;
  }

  .results-container {
    padding: 20px;
  }
}

.download-btn-container {
  text-align: center;
  margin-top: 20px;
}

.download-csv-btn {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.download-csv-btn:hover {
  background-color: #45a049;
}


.green {
  color: green;
}

.red {
  color: red;
}

.orange {
  color: orange;
}
