
.test-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  text-align: center;
}


.heading {
  font-family: 'Lucida Handwriting', cursive;
  font-size: 24px;
  margin-bottom: 20px;
}


.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}


.url-input {
  padding: 8px;
  width: 300px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}


.box {
  width: 120px;
  height: auto;
  transition: .5s linear;
  position: relative;
  display: block;
  overflow: hidden;
  padding: 15px;
  text-align: center;
  margin: 0 5px;
  background: transparent;
  text-transform: uppercase;
  font-weight: 600;
  font-family: cursive;
}

.box:before {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  height: 4px;
  width: 100%;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
  box-sizing: border-box;
  transform: translateX(100%);
}

.box:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  border-top: 4px solid transparent;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  transform: translateX(-100%);
}

.box:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.box:hover:before {
  border-color: #262626;
  height: 100%;
  transform: translateX(0);
  transition: .3s transform linear, .3s height linear .3s;
}

.box:hover:after {
  border-color: #262626;
  height: 100%;
  transform: translateX(0);
  transition: .3s transform linear, .3s height linear .5s;
}

button {
  color: black;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
}


.results-container {
  margin-top: 20px;
}


.results-heading {
  font-family: 'Lucida Handwriting', cursive;
  font-size: 20px;
  margin-bottom: 10px;
}


.tabs-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}


.tab-content {
  padding: 10px;
}


.speed-card {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin: 10px 0;
}


.speed-text {
  font-family: 'Cursive', cursive;
  font-size: 16px;
}


.speed-value {
  font-weight: bold;
}
