.button1 {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    width: 50px; 
    height: 30px; 
    background-image: linear-gradient(to top, #D8D9DB 0%, #fff 80%, #FDFDFD 100%);
    border-radius: 20px; 
    border: 0.5px #8F9092;
    transition: all 0.2s ease;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 10px; 
    font-weight: 600;
    color: #606060;
    text-shadow: 0 1px #ffffff;
}

.button1:hover {
    box-shadow: 0 2px 1px 0.5px #FCFCFC, 0 3px 4px #D6D7D9, 0 -2px 2px #CECFD1, 0 -3px 2px #FEFEFE, inset 0 0 1px 1px #CECFD1;
}

.button1:active {
    box-shadow: 0 2px 1px 0.5px #FCFCFC, 0 3px 4px #D6D7D9, 0 -2px 2px #CECFD1, 0 -3px 2px #FEFEFE, inset 0 0 2px 1px #999, inset 0 0 15px #aaa;
}

.button1:focus {
    box-shadow: 0 2px 1px 0.5px #FCFCFC, 0 3px 4px #D6D7D9, 0 -2px 2px #CECFD1, 0 -3px 2px #FEFEFE, inset 0 0 2px 1px #999, inset 0 0 15px #aaa;
}

.action-container {
    display: flex;
    align-items: center;
    position: relative; 
  }
  
  .dropdown-icon {
    margin-left: 8px; 
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%; 
    left: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 0.5em 0;
    z-index: 10; 
  }
  
  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 0.5em 1em;
    text-align: left;
  }
  
  .dropdown-item .icon {
    margin-right: 8px; 
  }
  
  .dropdown-item:hover {
    background-color: #f8f9fa;
  }
  
  .dropdown-text {
    border: none;
    background: transparent;
    color: inherit;
    font-size: 14px;
    font-weight: 600;
  }
  
  .pagination-controls {
    display: flex;
    align-items: center;
    gap: 10px; 
    margin-top: 10px;
    padding: 0 10px;
  }
  
  .pagination-info {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #606060;
  }
  
  .button2 {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    width: 75px; 
    height: 30px;
    background-image: linear-gradient(to top, #D8D9DB 0%, #fff 80%, #FDFDFD 100%);
    border-radius: 20px;
    border: 0.5px #8F9092;
    transition: all 0.2s ease;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 10px;
    font-weight: 600;
    color: #606060;
    text-shadow: 0 1px #ffffff;
  }
  
  .button2:hover {
    box-shadow: 0 2px 1px 0.5px #FCFCFC, 0 3px 4px #D6D7D9, 0 -2px 2px #CECFD1, 0 -3px 2px #FEFEFE, inset 0 0 1px 1px #CECFD1;
  }
  
  .button2:active {
    box-shadow: 0 2px 1px 0.5px #FCFCFC, 0 3px 4px #D6D7D9, 0 -2px 2px #CECFD1, 0 -3px 2px #FEFEFE, inset 0 0 2px 1px #999, inset 0 0 15px #aaa;
  }
  
  .button2:focus {
    box-shadow: 0 2px 1px 0.5px #FCFCFC, 0 3px 4px #D6D7D9, 0 -2px 2px #CECFD1, 0 -3px 2px #FEFEFE, inset 0 0 2px 1px #999, inset 0 0 15px #aaa;
  }
  