.dashboard {
  display: flex;
  flex-direction: column; 
  gap: 20px;
  margin-bottom: 20px;
}

.dashboard-section {
  height: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.dashboard-section h5 {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}


.select-url-section {
  width: 30%; 
  margin: 0 auto; 
}

.url-select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); 
  transition: border-color 0.3s ease; 
}

.url-select:focus {
  border-color: #007bff; 
  outline: none; 
}


.dropdown-container {
  position: relative;
  display: inline-block;
}


.dropdown-icon {
  cursor: pointer;
}


.dropdown-menu.show {
  display: block;
  position: absolute;
  top: 100%; 
  left: 0;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  padding: 0.5rem;
  min-width: 150px;
}

.dropdown-menu .dropdown-item {
  padding: 0.5rem;
  cursor: pointer;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #f0f0f0;
}

.table {
  margin-top: 20px;
}

.table th {
  text-align: center;
}

.table td {
  vertical-align: middle;
}

.chart-container {
  margin-top: 20px;
}
