.card {
  border-radius: 10px;
}
.result-card {
  border-left: 5px solid #007bff;
}

.card-background .card-sm {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%; 
  max-width: 600px; 
  background: rgba(255, 255, 255, 0.8); 
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.form-group, .form-input {
  width: 200px; 
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center; 
  gap: 1px; 
}

@media (max-width: 768px) {
  .form-select, .form-input {
    width: 150px;
  }
}


