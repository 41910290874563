.homepage-container {
  text-align: center;
}

.logo-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.logo {
  width: 250px;
  height: auto;
}

.background-image {
  background-image: url('../assets/bg.png');
  background-size: cover;
  background-position: center;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.heading-container {
  margin-right: 20px;
  text-align: right;
}

.heading-container h1 {
  color: white;
  font-size: 2em; 
  margin-bottom: 20px;
}

.login-btn {
  font-size: 13px;
  background: transparent;
  border: none;
  padding: 1em 1.5em;
  color: #ee0a0a;
  text-transform: uppercase;
  position: relative;
  transition: 0.5s ease;
  cursor: pointer;

}

.login-btn::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 0;
  background-color: #ffc506;
  transition: 0.5s ease;
}


.login-btn:hover {
  color: #1e1e2b;
  transition-delay: 0.5s;
}

.login-btn:hover::before {
  width: 100%;
}

.login-btn::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0;
  width: 100%;
  background-color: #ffc506;
  transition: 0.4s ease;
  z-index: -1;
}

.login-btn:hover::after {
  height: 100%;
  transition-delay: 0.4s;
  color: aliceblue;
}

.logout-btn {
  display: inline-block;
  width: 100px;
  height: 30px;
  border-radius: 10px;
  border: 0.5px solid #823a04;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in;
  z-index: 1;
 }
 
 .logout-btn::before,
 .logout-btn::after {
  content: '';
  position: absolute;
  top: 0;
  width: 0;
  height: 100%;
  transform: skew(15deg);
  transition: all 0.5s;
  overflow: hidden;
  z-index: -1;
 }
 
 .logout-btn::before {
  left: -10px;
  background: #eb5c03;
 }
 
 .logout-btn::after {
  right: -10px;
  background: #d26536;
 }
 
 .logout-btn:hover::before,
 .logout-btn:hover::after {
  width: 58%;
 }
 
 .logout-btn:hover span {
  color: #e0aaff;
  transition: 0.3s;
 }
 
 .logout-btn span {
  color: black;
  font-size: 10px;
  transition: all 0.3s ease-in;
 }